const FAQContent = [
  {
    title: 'What is the meaning of Lorem ipsum?',
    body: `
      Literally it does not mean anything. It is a sequence of words without a sense of Latin derivation that make up a text also known as filler text, fictitious, blind or placeholder.
    `,
  },
  {
    title: 'Why is Lorem Ipsum Dolor used?',
    body: `
      The Lorem Ipsum text is used to fill spaces designated to host texts that have not yet been published. They use programmers, graphic designers, typographers to get a real impression of the digital / advertising / editorial product they are working on.
    `,
  },
  {
    title: 'What is the most used version?',
    body: `
      Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    `,
  },
  {
    title: 'What are the origins of Lorem Ipsum Dolor Sit?',
    body: `
      Its origins date back to 45 BC. In fact, his words were randomly extracted from the De finibus bonorum et malorum , a classic of Latin literature written by Cicero over 2000 years ago.
    `,
  },
];

export default FAQContent;
